<template>
	<standard-container title="Gestione traduzioni" icon="mdi-home" show-buttons nocard>
		<v-row>
			<v-col cols="6" md="4" lg="3">
				<v-card width="250">
					<v-navigation-drawer permanent>
						<v-list>
							<v-list-item>
								<v-list-item-content>
									<v-list-item-title class="title">Sezioni</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
						</v-list>

						<v-divider></v-divider>

						<v-list dense>
							<v-list-item-group v-model="currentIndex" color="accent">
								<v-list-item v-for="(keys, idx) in Object.entries(allTranslations)" :key="idx" style="padding-top:0 !important;padding-bottom:0 !important; min-height: 25px;">
									<v-list-item-content style="padding-top:0 !important;padding-bottom:0 !important">
										<v-list-item-title>{{ keys[0] }}</v-list-item-title>
									</v-list-item-content>
								</v-list-item>
							</v-list-item-group>
						</v-list>

						<v-divider></v-divider>

						<v-list>
							<v-list-item>
								<v-list-item-action><v-switch v-model="showTranslatableElments"></v-switch></v-list-item-action>
								<v-list-item-content>
									<v-list-item-title>Debug</v-list-item-title>
									<v-list-item-subtitle>Elementi traducibili</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>
							<v-list-item>
								<v-list-item-action><v-switch v-model="showKeysTranslatableElments"></v-switch></v-list-item-action>
								<v-list-item-content>
									<v-list-item-title>Key</v-list-item-title>
									<v-list-item-subtitle>Chiavi elementi traducibili</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>
						</v-list>
					</v-navigation-drawer>
				</v-card>
			</v-col>
			<v-col>
				<v-card>
					<v-card-title class="text-h4">{{ currentSection }}</v-card-title>
					<v-card-text>
						<v-simple-table v-if="currentIndex>=0">
							<template v-slot:default>
								<thead>
									<tr>
										<th>
											key
										</th>
										<th v-for="(lang, langIdx) in languagesTable" :key="langIdx">
											{{ lang.Desc }}
										</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(item, idx) in currentEntries" :key="idx" @click="editKey(idx, item)">
										<td>{{ idx }}</td>
										<td v-for="(lang, langIdx) in languagesTable" :key="langIdx">
											{{ item[lang.Id] }}
										</td>
									</tr>
								</tbody>
							</template>
						</v-simple-table>
					</v-card-text>
				</v-card>

				<debug-panel v-if="currentEntries" label="currentEntries" class="mt-6">
					{{ Object.entries(currentEntries) }}
				</debug-panel>
			</v-col>
		</v-row>




		<!-- inizio dialog -->
		<v-dialog v-model="dialog" :fullscreen="$vuetify.breakpoint.name === 'xs'" persistent transition="scale-transition" max-width="800" @keydown.esc="dialog = false">
			<v-card>
				<v-toolbar dense :color="appSetup.appColor">
					<v-btn icon @click="dialog = false"><v-icon>mdi-close</v-icon></v-btn>
					<v-toolbar-title>{{ currentSection }} - {{ currentKey }}</v-toolbar-title>
					<v-spacer />
					<v-toolbar-items>
						<v-btn text dark :disabled="loading" :loading="loading" @click="updateKey">Save</v-btn>
					</v-toolbar-items>
				</v-toolbar>
				<v-container fluid>
					<v-row class="mt-4">
						<v-col v-for="(lang, langIdx) in languagesTable" :key="langIdx" cols="12">
							<v-textarea v-model="currentItems[lang.Id]" :label="lang.Desc" rows="4" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense"></v-textarea>
						</v-col>
					</v-row>
					<v-alert v-if="errors" type="error" dense text>
						{{ errors }}
					</v-alert>
				</v-container>
			</v-card>
		</v-dialog>									
		<!-- fine dialog -->	
		<!-- 
		<div class="row">
			<div class="col-sm-5 col-md-4 col-lg-3">
				<div class="panel-group">
					<ul class="list-unstyled">
						<li v-for="(keys, section) in allTranslations" class="panel panel-filled support-question " :class="{active:section===currentSection}">
							<a href="#" @click.prevent="setCurrentSection(section, keys)">
								<div class="panel-body no-bottom-padding">
									<p class=" no-bottom-margin" :class="{'font-bold c-white':section===currentSection}">{{ section }}</p>
								</div>
							</a>
						</li>
					</ul>
				</div>
			</div>
			<div class="col-sm-7 col-md-8 col-lg-9">

				<div class="panel panel-filled">
					<div class="panel-body no-bottom-padding">
						<div>
							<table class="table table-condensed table-striped table-hover lang-keys-table">
								<thead>
									<tr>
										<th><span style="color:#f6a821">{{ currentSection }}&nbsp;</span></th>
										<th v-for="lang in languages" :key="lang"><span>{{ lang }}</span></th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(klangs, key) in currentKeys" class="clickable" :class="{warning: key===currentKey}" @click.prevent="editKey(currentSection, key, klangs)">
										<th>{{ key }}</th>
										<td v-for="(klang, kindex) in klangs">{{ klang | truncate }}</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
		 -->

		<v-row>
			<v-col>
				<debug-panel label="allTranslations">
					{{ allTranslations }}
				</debug-panel>
			</v-col>
			<v-col>
				<debug-panel label="languagesTable">
					{{ languagesTable }}
				</debug-panel>
			</v-col>
			<v-col>
				<debug-panel label="languages">
					{{ languages }}
				</debug-panel>
			</v-col>
		</v-row>



    
		<!-- modale 
		<div id="editModal" class="modal fade in" tabindex="-1" role="dialog" :style="{display: showModal ? 'block' : 'none'}">
			<div class="modal-dialog ">
				<div class="modal-content">
					<div class="modal-body">
						<h4 class="m-t-none">{{ currentSection }} &raquo; {{ currentKey }}</h4>
						<p></p>
						<div style="max-height: 400px; overflow-y:scroll;">
							<table class="table">
								<tbody v-for="(key, index) in currentKlangsEdit">
									<tr>
										<th style="width:75px">{{ index }}</th>
										<td>
											<textarea v-model="currentKlangsEdit[index]" class="form-control" rows="5"></textarea>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-default" @click.prevent="modalClose()">Close</button>
						<button type="button" class="btn btn-accent" @click.prevent="modalSave()">Save changes</button>
					</div>
				</div>
			</div>
		</div>
		-->
	</standard-container>
</template>

<script>
import StandardContainer from '@/components/standard-container'
import DebugPanel from '@/components/debug-panel'

//
export default {
	name: 'TranslationsPage',
	components: { StandardContainer, DebugPanel },
	filters: {
		truncate: function (value) {
			if (!value) return ''
			value = value.toString()
			var originalLength = value.length
			var truncateLength = Math.min(32, originalLength)
			value = value.substring(0, truncateLength)
			if (originalLength > truncateLength) value = value + '...'
			return value
		}
	},
	data: function () {
		return {
			currentIndex:-1,
			dialog: false,
			loading: false,
			currentKey: null,
			currentItems: {},
			errors: null,
		}
	},
	computed: {
		appSetup() { return window.setup },
		viewDebugElements() { return this.$store.getters['viewDebugElements'] },
		tce() { return this.$store.getters['tipologiaCampiEdit'] },
		compactVisualization() { return this.$store.getters['compactVisualization'] },
		viewTranslatableElements() { return this.$store.getters['viewTranslatableElements'] },
		viewKeyTranslatableElements() { return this.$store.getters['viewKeyTranslatableElements'] },
		allTranslations() { return this.$store.getters['translation/allTranslations'] },
		languages() { return this.$store.getters['translation/languages'] },
		languagesTable() { return this.$store.getters['translation/languagesTable'] },
		currentSection() {
			return this.currentIndex>=0 ? Object.keys(this.allTranslations)[this.currentIndex] : null
		},
		currentEntries() {
			return this.currentIndex>=0 ? (Object.entries(this.allTranslations)[this.currentIndex])[1] : null
		},
		showTranslatableElments: {
			get() {
				return this.viewTranslatableElements
			},
			// eslint-disable-next-line no-unused-vars
			set(value) {
				this.$store.dispatch('toggleShowTranslatableElements')
			}
		},
		showKeysTranslatableElments: {
			get() {
				return this.viewKeyTranslatableElements
			},
			// eslint-disable-next-line no-unused-vars
			set(value) {
				this.$store.dispatch('toggleShowKeyTranslatableElements')
			}
		},
	},
	mounted() {
		this.caricaTraduzioni()
	},
	methods: {
		caricaTraduzioni() {
			this.currentIndex=-1
			this.loading = true
			this.$store.dispatch('genericApiPost', {apiUrl: '/setup/traduzioni/get'}).then(response => {
				this.$store.commit('translation/UPDATE_TRANSLATIONS', response.Translations)
				this.$store.commit('translation/UPDATE_ALLTRANSLATIONS', response.AllTranslations)
			}).catch(error => {
				console.error(error)
				this.errors = error
			}).finally(() => {
				this.loading = false
			})
		},

		// apre la modale per l'edit delle lingue (items)
		editKey(key, items) {
			this.currentKey = key
			this.currentItems = JSON.parse(JSON.stringify(items))  
			this.dialog = true
		},
		// salva le traduzoni 
		updateKey() {
			this.loading = true
			var request = {
				Section: this.currentSection,
				Key: this.currentKey,
				Items: this.currentItems
			}
			this.$store.dispatch('genericApiPost', {apiUrl: '/setup/traduzioni/set', value: request}).then(response => {
				this.$store.commit('translation/UPDATE_TRANSLATIONS', response.Translations)
				this.$store.commit('translation/UPDATE_ALLTRANSLATIONS', response.AllTranslations)
				this.dialog = false
			}).catch(error => {
				console.error(error)
				this.errors = error
			}).finally(() => {
				this.loading = false
			})
		},
	}
}
</script>


<style scoped lang="less">


</style>